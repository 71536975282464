export var STATISTICS_SERVICES_REDUCER_NAMESPACES = {
    statisticsRoot: 'statistics-root',
    mainStatistics: 'main-statistics',
    teamStatistics: 'team-statistics',
    activitiesStatistics: 'activities-statistics',
    rewardsStatistics: 'rewards-statistics',
    gratitudesStatistics: 'gratitudes-statistics',
    corporateCurrencyStatistics: 'corporate-currency-statistics',
    navigationStatisticsDashboard: 'navigation-statistics-dashboard',
};
